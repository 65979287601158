<template>
  <div class="user page">
    <Nav></Nav>
    <div class="wrap" style="padding-top:10px;min-height: 700px;">
      <a-layout>
        <a-layout-sider>
          <Menu></Menu>
        </a-layout-sider>
        <a-layout>
          <a-layout-header>
            <a-row type="flex" justify="center" align="middle" class="top">
              <a-col :span="6">
                <icon-font type="icon-bonuspoints" /> 添加地址
              </a-col>
            </a-row>
          </a-layout-header>
          <a-layout-content>
            <a-form-model ref="ruleForm" :model="form" :rules="rules" :label-col="labelCol" :wrapper-col="wrapperCol">
              <a-form-model-item ref="countryId" label="国家" prop="countryId">
                <a-select v-model="form.countryId" @change="changeCountry">
                  <a-select-option v-for="item in countryList" :key="item.id" :value="item.id">
                    {{ item.name }}
                  </a-select-option>
                </a-select>
              </a-form-model-item>
              <a-form-model-item ref="name" label="姓名" prop="name">
                <a-input v-model="form.name" @blur="() => {
                  $refs.name.onFieldBlur();
                }
                  " placeholder="请输入姓名"></a-input>
              </a-form-model-item>
              <a-form-model-item ref="phone" label="手机号" prop="phone">
                <a-input v-model="form.phone" @blur="() => {
                  $refs.phone.onFieldBlur();
                }
                  " placeholder="请输入手机号"></a-input>
              </a-form-model-item>
              <a-form-model-item v-if="form.countryId == 1" ref="region" label="省市区" prop="region">
                <a-cascader v-model="region" :options="options" placeholder="请选择省市区" @change="onChange" />
              </a-form-model-item>
              <a-form-model-item v-if="form.countryId == 2" ref="region" label="省市区" prop="region">
                <a-cascader v-model="region" :options="options2" placeholder="请选择省市" @change="onChange2" />
              </a-form-model-item>
              <a-form-model-item ref="detail" label="详细地址" prop="detail">
                <a-input v-model="form.detail" @blur="() => {
                  $refs.detail.onFieldBlur();
                }
                  " placeholder="请输入详细地址"></a-input>
              </a-form-model-item>
              <a-form-model-item ref="zipCode" label="邮编">
                <a-input v-model="form.zipCode" placeholder="请输入邮编" :readonly="true">
                </a-input>
              </a-form-model-item>
              <a-form-model-item label="是否默认" prop="default">
                <a-switch v-model="form.default" checked-children="是" un-checked-children="否" />
              </a-form-model-item>
              <a-form-item :wrapper-col="{ span: 12, offset: 5 }">
                <a-button type="primary" html-type="submit" @click="onSubmit">
                  保存
                </a-button>
                <a-button type="default" @click="back" style="margin-left: 100px;">
                  返回
                </a-button>
              </a-form-item>
            </a-form-model>
          </a-layout-content>
        </a-layout>
      </a-layout>
    </div>
    <Foot></Foot>
    <a-modal v-model="visible" title="选择区域" @ok="hideCity" :footer="null" :width="800" :height="500">
      <a-select show-search :value="value" placeholder="邮编搜索" style="width: 100%" :default-active-first-option="false"
        :show-arrow="false" :filter-option="false" :not-found-content="null" @search="fetchCity" @change="handleChange">
        <a-spin v-if="fetching" slot="notFoundContent" size="small" />
        <a-select-option v-for="d in data" :key="d.code">
          <div class="cityItem u-flex u-row-between">
            <div class="code">{{ d.code }}</div>
            <div class="info">{{ d.province }}-{{ d.city }}</div>
          </div>
        </a-select-option>
      </a-select>
      <br>
      <br>
      <br>
      <br>
      <br>
      <br>
    </a-modal>
  </div>
</template>

<script>
import Foot from "@/components/foot";
import Nav from "@/components/Nav.vue";
import Menu from "../user/components/menu";
import {
  Icon
} from "ant-design-vue";
const IconFont = Icon.createFromIconfontCN({
  scriptUrl: "//at.alicdn.com/t/font_2747721_gimu9jlw02u.js",
});
import {
  options
} from "/mock/city.js";
import {
  options2
} from "/mock/citycan.js";
export default {
  components: {
    Nav,
    Foot,
    Menu,
    IconFont,
  },
  data() {
    let mobile = (rule, value, callback) => {
      if (this.countryId == 1) {
        if (value === "") {
          callback(new Error("请输入手机号"));
        } else if (!/^1[0-9]{10}$/.test(value)) {
          callback(new Error("请输入正确的手机号码"));
        } else {
          callback();
        }
      } else {
        if (value === "") {
          callback(new Error("请输入手机号"));
        } else {
          callback();
        }
      }
    };
    return {
      user: {},
      op: '',
      options: [],
      options2: [],
      labelCol: {
        span: 4
      },
      wrapperCol: {
        span: 14
      },
      other: "",
      tag: "",
      region: [],
      visible: false,
      data: [],
      value: [],
      fetching: false,
      form: {
        errorType: ["message"],
        labelPosition: "top",
        id: 0,
        countryId: 1,
        countryName: "中国",
        name: "",
        phone: "",
        region: "",
        detail: "",
        default: false,
        provinceId: 0,
        cityId: 0,
        areaId: 0,
        provinceName: "",
        cityName: "",
        areaName: "",
        zipCode: '',
      },
      countryList: [{
        id: 1,
        name: "中国",
      },
      {
        id: 2,
        name: "加拿大",
      },
      ],
      rules: {
        name: [{
          required: true,
          message: "请输入姓名",
          trigger: "blur"
        },
        {
          min: 2,
          max: 50,
          message: "请输入正确的姓名",
          trigger: "blur"
        },
        ],
        phone: [{
          required: true,
          message: "请输入手机号码",
          trigger: "blur"
        },
        {
          validator: mobile,
          trigger: "blur"
        },
        ],
        countryId: [{
          required: true,
          message: "请选择国家",
          trigger: "change"
        },],
        region: [{
          required: true,
          message: "请选择省市区",
          trigger: "change"
        },],
        detail: [{
          required: true,
          message: "请输入详细地址",
          trigger: "blur"
        },],
        zipCode: [{
          required: true,
          message: "请输入邮编",
          trigger: "blur"
        },],
      },
    };
  },
  computed: {},
  created() {
    if (this.$route.name === "Home") this.main_swiper = true;
  },
  watch: {
    // 如果路由有变化，会再次执行该方法
    $route: "fetchDate",
  },
  mounted() {
    this.user = this.storage.getItem("user");
    if (!this.user) {
      this.getUserInfo();
    }
    this.options = options; //给省市区数据源
    this.options2 = options2; //给省市区数据源
    let geturl = window.location.href;
    let getqyinfo = geturl.split("?")[1];
    let getqys = getqyinfo.split("&");
    let obj = {}; //创建空对象，接收截取的参数
    for (let i = 0; i < getqys.length; i++) {
      let item = getqys[i].split("=");
      let key = item[0];
      let value = item[1];
      obj[key] = value;
    }
    this.form.id = obj.id;
    this.op = obj.op;
    if (this.form.id > 0) {
      this.api
        .getAddressDetail({
          id: this.form.id,
        })
        .then((res) => {
          console.log(res);
          this.form.name = res.name;
          this.form.typeId = res.typeId;
          this.form.countryId = res.countryId;
          this.form.phone = res.mobile;
          this.form.provinceId = res.provinceId;
          this.form.provinceName = res.provinceName;
          this.form.cityId = res.cityId;
          this.form.cityName = res.cityName;
          this.form.areaId = res.areaId;
          this.form.areaName = res.areaName;
          this.form.region = res.areaInfo;
          this.form.detail = res.address;
          this.form.default = res.isDefault == 1 ? true : false;
          this.form.zipCode = res.zipCode;
          if (this.form.countryId == 1) {
            this.region = [
              this.form.provinceId,
              this.form.cityId,
              this.form.areaId,
            ];
          }else{
            this.region = [
              this.form.provinceId,
              this.form.cityId
            ];
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  },
  methods: {
    fetchDate() {
      if (this.$route.name !== "Home") {
        this.main_swiper = false;
      } else {
        this.main_swiper = true;
      }
    },
    getUserInfo() {
      this.api
        .getuserDetail({})
        .then((res) => {
          this.storage.setItem("user", res);
          this.user = res;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getData() { },
    onSubmit() {
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          this.api.seaveAddress({
            id: this.form.id,
            typeId: 1,
            countryId: this.form.countryId,
            name: this.form.name,
            mobile: this.form.phone,
            mobile2: '',
            provinceId: this.form.provinceId,
            provinceName: this.form.provinceName,
            cityId: this.form.cityId,
            cityName: this.form.cityName,
            areaId: this.form.areaId,
            areaName: this.form.areaName,
            areaInfo: this.form.region,
            address: this.form.detail,
            isDefault: this.form.default ? 1 : 0,
            zipCode: this.form.zipCode,
          }).then(res => {
            if (this.op == "orderReceive") {
              let order = this.storage.getItem("order");
              if (!order) {
                order = {};
              }
              let receive = {};
              receive.id = res;
              receive.typeId = this.form.typeId;
              receive.countryId = this.form.countryId;
              receive.name = this.form.name;
              receive.mobile = this.form.phone;
              receive.mobile2 = this.form.mobile2;
              receive.areaInfo = this.form.region;
              receive.address = this.form.detail;
              receive.zipCode = this.form.zipCode;
              receive.isDefault = this.form.default ? 1 : 0;

              order.receive = receive;
              this.storage.setItem("order", order);
              this.$router.push("/Buy");
            } else {
              this.$router.back();
            }
          }).catch(err => {
            console.log(err);
          });
        } else {
          return false;
        }
      });
    },
    //国家改变
    changeCountry() {
      this.region = [];
      this.form.provinceId = 0;
      this.form.provinceName = '';
      this.form.cityId = 0;
      this.form.cityName = '';
      this.form.areaId = 0;
      this.form.areaName = '';
      this.form.region = '';
      this.form.zipCode = '';
    },
    //省市区改变
    onChange(value, selectedOptions) {
      this.form.region = value;
      this.form.provinceId = selectedOptions[0].value;
      this.form.provinceName = selectedOptions[0].label;
      this.form.cityId = selectedOptions[1].value;
      this.form.cityName = selectedOptions[1].label;
      this.form.areaId = selectedOptions[2].value;
      this.form.areaName = selectedOptions[2].label;
      this.form.region =
        selectedOptions[0].label +
        "-" +
        selectedOptions[1].label +
        "-" +
        selectedOptions[2].label;
    },
    //加拿大省市区改变
    onChange2(value, selectedOptions) {
      this.form.region = value;
      this.form.provinceId = selectedOptions[0].value;
      this.form.provinceName = selectedOptions[0].label;
      this.form.cityId = selectedOptions[1].value;
      this.form.cityName = selectedOptions[1].label;
      this.form.areaId = 0;
      this.form.areaName = "";
      this.form.region =
        selectedOptions[0].label +
        "-" +
        selectedOptions[1].label
    },
    //加拿大邮编区域搜索
    fetchCity(value) {
      this.api.getCitys2({
        k: value,
      }).then(res => {
        this.data = res;
        this.fetching = false;
      }).catch(err => {
        this.fetching = false;
        console.log(err);
      });
    },
    //邮编选择加拿大区域
    handleChange(value) {
      console.log(value);
      this.data.forEach((item) => {
        if (item.code == value) {
          // this.form.region = value;
          // this.form.provinceId = item.id;
          // this.form.provinceName = item.province;
          // this.form.cityId = item.id;
          // this.form.cityName = item.city;
          // this.form.areaId = item.id;
          // this.form.areaName = item.code;
          // this.form.region = item.province + "-" + item.city;
          this.form.zipCode = item.province + "-" + item.city + "-" + item.code;

          this.visible = false;
          return;
        }
      });
    },
    //显示区域
    showCity() {
      this.visible = true;
    },
    //关闭区域
    hideCity() {
      this.visible = false;
    },
    //返回
    back() {
      this.$router.back();
    }
  },
};
</script>


<style lang="less">
@import "~ant-design-vue/dist/antd.less";

.page {
  background: #f5f5f5;
}

.wrap {
  height: 100%;

  // padding-bottom: 20px;
}

.ant-layout {
  height: 100%;
}

.ant-layout-sider,
.ant-layout-content,
.ant-layout-header,
.ant-layout-footer {
  background: #ffffff !important;
  padding: 10px;
}

.ant-layout-sider {
  margin-right: 10px;
  text-align: center;
}

.ant-layout-content {
  margin-top: 10px;
}

.ant-layout-header {
  height: auto;

  .top {
    .ant-col {
      text-align: center;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
    }

    .anticon {
      font-size: 24px;
      margin-right: 10px;
    }
  }
}

.user {
  .user-name {
    font-size: 14px;
    margin-top: 10px;
  }

  .other-info {
    text-align: left;
    margin-left: 30px;

    span {
      color: @text-color-secondary;
    }
  }
}

.menu {
  text-align: left;
  padding-left: 30px;
  line-height: 28px;
  margin-top: 30px;
  border-top: 1px solid #e2e2e2;

  dl {
    padding-top: 10px;

    dt {
      font-size: 16px;
      line-height: 28px;
    }

    dd {
      color: @text-color-secondary;
    }
  }
}

div.content-wrap {
  padding: 15px;
}

.num {
  color: red;
  padding-right: 10px;
}

.cityItem {
  width: 100%;

  .code {
    width: 150px;
  }

  info {
    width: 100%;
  }
}
</style>